import { useFeatureToggle } from '@shared-snap/snap/hooks/use-feature-toggles'
import { createFileRoute } from '@tanstack/react-router'
import { SupersetHousekeepingReport } from 'components/organisms/reports/superset/superset-housekeeping-report'
import { addressBase } from './reports.optional'

export const Route = createFileRoute('/snap/_authenticated/reports/housekeeping')({
    component: RouteComponent
})

function RouteComponent() {
    const { isFeatureOn } = useFeatureToggle()

    return isFeatureOn('superset') ? (
        <SupersetHousekeepingReport />
    ) : (
        <iframe width="100%" height="100%" src={`${addressBase}/reports/housekeeping`} style={{ border: 'none' }} />
    )
}
