import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg, useCurrentUser } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useAddCommentToIssue } from '@shared-snap/snap/components/issues/hooks/use-add-comment-to-issue'
import { useAssignIssue } from '@shared-snap/snap/components/issues/hooks/use-assign-issue'
import { useChangeIssueDueDate } from '@shared-snap/snap/components/issues/hooks/use-change-issue-due-date'
import { useChangeIssuePriority } from '@shared-snap/snap/components/issues/hooks/use-change-issue-priority'
import { useCompleteIssue } from '@shared-snap/snap/components/issues/hooks/use-complete-issue'
import { useDeleteImageFromIssue } from '@shared-snap/snap/components/issues/hooks/use-delete-image-from-issue'
import { useDeleteIssue } from '@shared-snap/snap/components/issues/hooks/use-delete-issue'
import { useIssueImageText } from '@shared-snap/snap/components/issues/hooks/use-issue-image-text'
import { useFetchCommentivities, useIssueData, useIssueUsersOptions } from '@shared-snap/snap/components/issues/hooks/use-issues'
import { useRenameIssue } from '@shared-snap/snap/components/issues/hooks/use-rename-issue'
import { useUncompleteIssue } from '@shared-snap/snap/components/issues/hooks/use-uncomplete-issue'
import { CommentivitiesList } from '@shared-snap/snap/components/issues/ui/commentivities'
import { AreaInfo, Assignees, ChangedInfo, CreatedInfo, DueDate, IssueNumber } from '@shared-snap/snap/components/issues/ui/issue'
import { getStatusColor } from '@shared-snap/snap/components/issues/utils'
import { PrioritySign } from '@shared-snap/snap/components/molecules/priority-sign'
import { useFeatureToggle } from '@shared-snap/snap/hooks/use-feature-toggles'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { CommentivityStruct, IssueStruct } from '@shared/firestore-structs'
import { cleanHashTags } from '@shared/helpers'
import { type CategorySelection, updateIssueCategories } from '@shared/issue-data'
import { useRouter } from '@tanstack/react-router'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button'
import { DatePicker } from 'components/atoms/date-picker/date-picker'
import { DueDateSign } from 'components/atoms/due-date-sign'
import { IconButton } from 'components/atoms/icon-button'
import { InitialsCircle } from 'components/atoms/initials-circle/initials-circle'
import Input from 'components/atoms/input/input'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { RoundButton } from 'components/atoms/round-button'
import Tooltip from 'components/atoms/tooltip'
import { AssigneesBlock } from 'components/molecules/assignees-block'
import { HashtagsSelect } from 'components/molecules/issues/hashtags-select'
import { Images } from 'components/molecules/issues/images'
import { isEqual } from 'lodash'
import moment from 'moment'
import { type SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CategorySelectionBlock } from './categories-modal/categories-modal'
import { UsersModal } from './users-modal/users-modal'

export function IssueModal({
    issueKey
}: {
    issueKey: string
}) {
    const router = useRouter()
    const {
        LL: { tooltips, settingsWeb }
    } = useI18nContext()
    const issue = useIssueData(issueKey, asFirebase(firebaseWrapped))
    const commentivities = useFetchCommentivities(issueKey)

    const onModalClose = () => {
        const currentPath = router.state.location.pathname
        const newPath = currentPath.split('/').slice(0, -1).join('/')

        router.navigate({ to: newPath })
    }

    return (
        <>
            <Modal className="w-3/4 h-3/4 overflow-y-scroll" onClose={onModalClose}>
                {!issue && <UI.Loader />}
                {issue && <Content onClose={onModalClose} issue={issue} commentivities={commentivities} />}
            </Modal>
            <Tooltip id="issue-priority-tooltip" text={tooltips.setPriority()} />
            <Tooltip id="assign-tooltip" text={tooltips.assign()} />
            <Tooltip id="categories-tooltip" text={settingsWeb.categories.categories()} />
            <Tooltip id="due-date-tooltip" text={tooltips.dueDate()} />
            <Tooltip id="complete-issue-tooltip" text={tooltips.complete()} />
            <Tooltip id="delete-tooltip" text={tooltips.delete()} />
            <Tooltip id="category-selection-tooltip" />
            {/* <Tooltip id="share-tooltip" text="Share open link" /> */}
        </>
    )
}

function Content({ onClose, issue, commentivities }: { onClose: () => void; issue: IssueStruct; commentivities: CommentivityStruct[] }) {
    const addComment = useAddCommentToIssue(asFirebase(firebaseWrapped))
    const deleteImage = useDeleteImageFromIssue(asFirebase(firebaseWrapped))
    const updateImageText = useIssueImageText(asFirebase(firebaseWrapped), issue.key)

    const disabled = issue.status === 'completed' || issue.status === 'deleted'

    const [isImageModalOpen, setIsImageModalOpen] = useState(false)
    const [imageUrl, setImageUrl] = useState('')

    const oepnedImage = useMemo(() => issue.items?.find(item => item.thumbUrl === imageUrl), [issue.items, imageUrl])

    const handleUpdateImageText = (text: string) => {
        if (oepnedImage?.key) {
            updateImageText(oepnedImage.key, { content: text })
        }
    }

    const handleOpenImageModal = (url: string) => {
        setImageUrl(url)
        setIsImageModalOpen(true)
    }

    const handleDeleteImage = () => {
        deleteImage(issue.key, oepnedImage?.key ?? '')
        setIsImageModalOpen(false)
    }

    async function onSubmitComment(text: string) {
        await addComment(issue.key, text)
    }

    return (
        <>
            <Header issue={issue} onClose={onClose} />
            <div className="w-full px-5">
                <Info issue={issue} />
                <Images handleOpenImageModal={handleOpenImageModal} issueKey={issue.key} items={issue.items ?? []} />
                <Commentivities commentivities={commentivities} />
                <div className="sticky bottom-0 pb-2 bg-white">
                    <CommentInput disabled={disabled} onSubmitComment={onSubmitComment} />
                </div>
            </div>
            <ImageModal
                imageUrl={imageUrl}
                isOpen={isImageModalOpen}
                imageText={oepnedImage?.text?.content}
                handleDeleteImage={handleDeleteImage}
                handleUpdateImageText={handleUpdateImageText}
                onClose={() => setIsImageModalOpen(false)}
            />
        </>
    )
}

const ImageModal = ({
    onClose,
    handleDeleteImage,
    handleUpdateImageText,
    isOpen,
    imageUrl,
    imageText
}: {
    onClose: () => void
    handleDeleteImage: () => void
    handleUpdateImageText: (text: string) => void
    isOpen: boolean
    imageUrl: string
    imageText: string | undefined
}) => {
    const [imageSize, setImageSize] = useState<Record<string, string | number>>({ width: 'fit-content', height: 'fit-content' })
    const [isLoaded, setIsLoaded] = useState(false)
    const [text, setText] = useState(imageText || '')
    const [isTextShown, setIsTextShown] = useState(true)
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const resizeTextarea = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
        }
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            e.currentTarget.blur()
            handleUpdateImageText(text)
        }
    }

    useEffect(() => {
        setText(imageText || '')
    }, [imageText])

    useEffect(() => {
        setTimeout(() => resizeTextarea(), 0)
    }, [text, isTextShown, isLoaded])

    useEffect(() => {
        if (isOpen && imageUrl) {
            const img = new Image()
            img.src = imageUrl
            img.onload = () => {
                const itemViewHeight = window.innerHeight - 100

                setImageSize({
                    height: itemViewHeight
                })

                setIsLoaded(true)
            }
        }
    }, [isOpen, imageUrl])

    const handleClose = () => {
        onClose()
        setIsLoaded(false)
        setImageSize({ height: 'fit-content' })
    }

    return (
        <Modal isOpen={isOpen && isLoaded} onClose={handleClose}>
            {isLoaded && (
                <div
                    className="relative flex justify-center items-center"
                    style={{
                        height: imageSize.height
                    }}>
                    <div className="absolute top-0 right-0 p-2 flex gap-x-2">
                        <RoundButton
                            className="cursor-pointer hover:opacity-70"
                            icon="specta-icon-text"
                            backgroundColor="snap-white"
                            onClick={() => setIsTextShown(prev => !prev)}
                        />
                        <RoundButton
                            className="cursor-pointer hover:opacity-70"
                            icon="specta-trash"
                            backgroundColor="snap-white"
                            onClick={handleDeleteImage}
                        />
                        <RoundButton
                            className="cursor-pointer hover:opacity-70"
                            icon="specta-close"
                            backgroundColor="snap-white"
                            onClick={handleClose}
                        />
                    </div>

                    {isTextShown && (
                        <div className="absolute w-full">
                            <textarea
                                ref={textareaRef}
                                rows={1}
                                style={{ backgroundColor: 'rgba(0,0,0,0.5)', resize: 'none' }}
                                className="text-white p-2 w-full text-sm outline-none"
                                value={text}
                                onChange={e => setText(e.target.value)}
                                onKeyPress={handleKeyPress}
                                onBlur={() => handleUpdateImageText(text)}
                            />
                        </div>
                    )}

                    <img className="w-full h-full object-cover" src={imageUrl} alt="issue image" />
                </div>
            )}
        </Modal>
    )
}

function Header({ issue, onClose }: { issue: IssueStruct; onClose: () => void }) {
    const [usersModalOpen, setUsersModalOpen] = useState(false)
    const changePriority = useChangeIssuePriority(asFirebase(firebaseWrapped), issue.key)
    const [selectedUsers, usersOptions] = useIssueUsersOptions(issue.key)
    const assign = useAssignIssue(asFirebase(firebaseWrapped), issue.key)

    const disabled = issue.status === 'completed' || issue.status === 'deleted'

    async function onAssign(userKeys: string[]) {
        await assign(userKeys)
    }

    return (
        <ModalHeader onClose={onClose} className="bg-white h-16 overflow-y-visible">
            <UsersModal
                initialSelected={selectedUsers}
                usersOptions={usersOptions}
                isOpen={usersModalOpen}
                onClose={() => setUsersModalOpen(false)}
                onAssign={onAssign}
            />
            <div className="relative flex justify-between">
                <div className="absolute flex gap-12">
                    <div data-tooltip-id="issue-priority-tooltip">
                        <PrioritySign
                            disabled={disabled}
                            priority={issue.priority}
                            onClick={changePriority}
                            backgroundColor="snap-mid-silver"
                        />
                    </div>
                    <div data-tooltip-id="assign-tooltip">
                        <RoundButton
                            icon="specta-assign-user"
                            backgroundColor="snap-mid-silver"
                            onClick={() => setUsersModalOpen(true)}
                            disabled={disabled}
                        />
                    </div>
                    <div data-tooltip-id="due-date-tooltip">
                        <DueDateButton disabled={disabled} issue={issue} />
                    </div>
                    <div data-tooltip-id="complete-issue-tooltip">
                        <CompleteButton disabled={disabled} issueKey={issue.key} status={issue.status} />
                    </div>
                </div>
                <div className="m-auto">
                    <StatusBar issue={issue} />
                </div>
                <div className="absolute flex gap-12 right-12">
                    <div data-tooltip-id="delete-tooltip">
                        <DeleteButton disabled={disabled} issueKey={issue.key} />
                    </div>
                    {/* <div data-tooltip-id="share-tooltip">
                        <RoundButton icon="specta-share" backgroundColor="snap-mid-silver" onClick={() => null} />
                    </div> */}
                </div>
            </div>
        </ModalHeader>
    )
}

function DeleteButton({ issueKey, disabled = false }: { issueKey: IssueStruct['key']; disabled?: boolean }) {
    const deleteIssue = useDeleteIssue(asFirebase(firebaseWrapped))
    const onClick = async () => await deleteIssue(issueKey)

    return <RoundButton disabled={disabled} icon="specta-trash" backgroundColor="snap-mid-silver" onClick={onClick} />
}

function DueDateButton({ disabled = false, issue }: { disabled?: boolean; issue: IssueStruct }) {
    const changeDueDate = useChangeIssueDueDate(asFirebase(firebaseWrapped))
    const currentOrg = useCurrentOrg()

    async function onChange(date: string) {
        await changeDueDate(issue, date)
    }

    return (
        <DatePicker
            disabled={disabled}
            value={(issue.startDate ? moment.tz(issue.startDate, currentOrg.timezone) : moment()).format('YYYY-MM-DD')}
            onChange={onChange}
            withTrigger={{
                Trigger: <RoundButton disabled={disabled} icon="specta-duedate" backgroundColor="snap-mid-silver" onClick={() => null} />,
                id: 'issue-datepicker'
            }}
        />
    )
}

function StatusBar({ issue }: { issue: IssueStruct }) {
    const {
        LL: { tooltips }
    } = useI18nContext()
    const text = issue.status.charAt(0).toUpperCase() + issue.status.slice(1)
    const color = useMemo(() => getStatusColor(issue.status), [issue.status])
    const completed = issue.status === 'completed' || issue.status === 'deleted'

    return (
        <div className="flex items-center justify-center gap-2">
            <span className={`text-${color}`}>{text}</span>
            {completed && (
                <>
                    <div data-tooltip-id="incomplete-tooltip">
                        <UncompleteButton issue={issue} />
                    </div>
                    <Tooltip id="incomplete-tooltip" text={tooltips.incomplete()} />
                </>
            )}
        </div>
    )
}

function UncompleteButton({ issue }: { issue: IssueStruct }) {
    const uncompleteIssue = useUncompleteIssue(asFirebase(firebaseWrapped))
    const onClick = async () => await uncompleteIssue(issue.key, issue.assignedTo)

    return <RoundButton icon="specta-recycling" backgroundColor="snap-mid-silver" onClick={onClick} />
}

function CompleteButton({
    issueKey,
    status,
    disabled = false
}: {
    issueKey: IssueStruct['key']
    status: IssueStruct['status']
    disabled?: boolean
}) {
    const completeIssue = useCompleteIssue(asFirebase(firebaseWrapped))
    const completed = status === 'completed'
    const onClick = async () => await completeIssue(issueKey)

    return (
        <RoundButton
            disabled={disabled}
            icon="specta-checkmark"
            backgroundColor={completed ? 'snap-green' : 'snap-mid-silver'}
            iconColor={completed ? 'snap-white' : 'snap-black'}
            onClick={onClick}
        />
    )
}

function Info({ issue }: { issue: IssueStruct }) {
    const assignIssue = useAssignIssue(asFirebase(firebaseWrapped), issue.key)
    const changeDueDate = useChangeIssueDueDate(asFirebase(firebaseWrapped))
    const overdue = useMemo(() => moment(issue.startDate).diff(moment().startOf('day'), 'days') < 0, [issue.startDate])

    const onDueDateRemove = async () => {
        await changeDueDate(issue, null)
    }

    const { isFeatureOn } = useFeatureToggle()

    const currentUser = useCurrentUser()
    const org = useCurrentOrg()

    const [issueCategorySelection, setCategorySelection] = useState<CategorySelection>(issue.categories ?? {})

    const categorySelection = useMemo(() => issue.categories, [issue.categories])

    const setCategoryAction = useCallback(
        (action: SetStateAction<CategorySelection>) => {
            setCategorySelection(prev => {
                const newState = typeof action === 'function' ? (action as (prev: CategorySelection) => CategorySelection)(prev) : action

                const categoriesChanged = !isEqual(newState, issueCategorySelection)

                if (categoriesChanged) {
                    updateIssueCategories(asFirebase(firebaseWrapped), { issueKey: issue.key, currentUser }, org, newState).catch(
                        console.error
                    )
                }

                return newState
            })
        },
        [issue.key, currentUser, org, issueCategorySelection]
    )

    return (
        <div className="w-full">
            <AreaAndIssueNumber area={issue.area} issueNumber={issue.issueNumber} status={issue.status} />
            <div className="flex flex-col gap-6">
                <Name issue={issue} />
                <div className="flex gap-2 items-center">
                    <DueDate dueDate={issue.startDate} Render={props => <DueDateSign {...props} />} />
                    {issue.startDate && (
                        <IconButton icon="specta-close" color={overdue ? 'snap-red' : 'sweeply-gray'} size="xs" onClick={onDueDateRemove} />
                    )}
                </div>
                <div className="flex items-center justify-between">
                    <ChangedInfo
                        updated={issue.updated}
                        Render={({ text }) => (
                            <UI.Text size="sm" color="snap-silver">
                                {text}
                            </UI.Text>
                        )}
                    />
                    <CreatedInfo
                        created={issue.created}
                        Render={({ text }) => (
                            <UI.Text size="sm" color="snap-silver">
                                {text}
                            </UI.Text>
                        )}
                    />
                </div>
                {isFeatureOn('hashtag-killer') ? (
                    <>
                        {org.categories && (
                            <CategorySelectionBlock
                                categoryTextProps={{
                                    size: 'md',
                                    weight: 'normal'
                                }}
                                categorySelection={categorySelection ?? {}}
                                setCategorySelection={setCategoryAction}
                            />
                        )}
                    </>
                ) : (
                    <HashtagsSelect issueKey={issue.key} name={issue.name} />
                )}
                <Assignees
                    onUnassign={async () => await assignIssue([])}
                    assignedContacts={issue.assignedTo}
                    Render={props => <AssigneesBlock {...props} />}
                />
            </div>
        </div>
    )
}

function AreaAndIssueNumber({
    area,
    issueNumber,
    status
}: {
    area: IssueStruct['area']
    issueNumber: IssueStruct['issueNumber']
    status: IssueStruct['status']
}) {
    return (
        <div className="flex items-center justify-between w-full mt-3 mb-3">
            <AreaInfo
                issueArea={area}
                Render={({ text }) => (
                    <UI.Text size="sm" color="snap-mid-gray">
                        {text}
                    </UI.Text>
                )}
            />
            <IssueNumber
                issueNumber={issueNumber}
                status={status}
                Render={({ text }) => (
                    <UI.Text size="sm" color={status === 'assigned' ? 'snap-yellow' : 'snap-black'}>
                        {text}
                    </UI.Text>
                )}
            />
        </div>
    )
}

function Name({ issue }: { issue: IssueStruct }) {
    const {
        LL: { issues }
    } = useI18nContext()
    const renameIssue = useRenameIssue(asFirebase(firebaseWrapped))
    const issueName = useMemo(() => cleanHashTags(issue.name), [issue])
    const [isEditing, setIsEditing] = useState(false)

    const handleIssueNameChange = async (val: string) => {
        const trimmedValue = val.trim()
        const trimmedPrevValue = issueName.trim()

        if (trimmedValue === trimmedPrevValue) return

        setIsEditing(false)
        await renameIssue(issue.key, trimmedValue)
    }
    return (
        <div className="relative">
            <Input
                initialValue={issueName.trim()}
                name="issue-name"
                placeholder={issues.changeNamePlaceholder()}
                onSave={handleIssueNameChange}
                onClick={() => setIsEditing(true)}
                className={`w-full cursor-pointer p-3 rounded-md border border-snap-light-gray`}
            />
            {isEditing && (
                <IconButton
                    icon="specta-checkmark"
                    size="sm"
                    color="snap-teal"
                    className="absolute top-2 right-2"
                    onClick={() => handleIssueNameChange}
                />
            )}
        </div>
    )
}

const DateElement = ({ createdTime, createdDay }: { createdTime: string; createdDay: string }) => {
    return (
        <span className="text-xs text-sweeply-mid-gray">
            {createdTime}, {createdDay}
        </span>
    )
}
interface CommentivitiesProps {
    commentivities: CommentivityStruct[]
}

function Commentivities({ commentivities }: CommentivitiesProps) {
    const {
        LL: { activities }
    } = useI18nContext()
    return (
        <div className="flex flex-col gap-3 my-4 h-fit max-h-2/3">
            <UI.Text size="md" weight="normal">
                {activities.name()}
            </UI.Text>
            <div className="h-fit max-h-[250px] overflow-y-scroll">
                <CommentivitiesList
                    RenderActivity={({ createdDay, createdTime, text }) => (
                        <div className="flex flex-col">
                            <DateElement createdDay={createdDay} createdTime={createdTime} />
                            <span className="text-sm">{text}</span>
                        </div>
                    )}
                    RenderComment={({ createdDay, createdTime, text, initials, name }) => (
                        <div className="flex flex-col gap-1">
                            <DateElement createdDay={createdDay} createdTime={createdTime} />
                            <div className="flex items-center gap-2">
                                <InitialsCircle initials={initials} name={name} size="sm" />
                                <span className="text-sm">{name}</span>
                            </div>
                            <div className="p-2 rounded bg-sweeply-light-gray max-w-max">
                                <span className="text-sm">{text}</span>
                            </div>
                        </div>
                    )}
                    commentivities={commentivities}
                />
            </div>
        </div>
    )
}

function CommentInput({ onSubmitComment, disabled }: { onSubmitComment: (comment: string) => void; disabled?: boolean }) {
    const {
        LL: { issues }
    } = useI18nContext()
    const [inputValue, setInputValue] = useState<string>('')
    const handleInputChange = (value: string) => {
        setInputValue(value)
    }

    const handleSubmitComment = () => {
        if (inputValue.length > 0) {
            onSubmitComment(inputValue)
            setInputValue('')
        }
    }
    return (
        <div className="flex justify-between items-center border-t border-snap-light-gray pt-2 bg-white">
            <Input
                initialValue={inputValue}
                onChange={handleInputChange}
                name="comment"
                placeholder={issues.commentInputPlaceholder()}
                className="border-none flex-1"
            />
            <Button onClick={handleSubmitComment} disabled={disabled}>
                {issues.commentPost()}
            </Button>
        </div>
    )
}
