import { useHousekeepingOverviewDataStatus } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-housekeeping-overview'
import { useTaskData, useTasks } from '@shared-snap/snap/components/my-tasks/hooks/use-tasks'
import { combineFirestoreFetchStatus } from '@shared-snap/snap/infra/firestore-atom'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { createFileRoute } from '@tanstack/react-router'
import { AreaSummaryModal } from 'components/organisms/area-summary-modal'
import { IssueModal } from 'components/organisms/issue-modal'
import { match } from 'ts-pattern'
import { GeneralTaskModal } from '../../../components/organisms/general-task-modal'
import { prefix } from '../../__root'

export const Route = createFileRoute(`${prefix}/_authenticated/taskboard/$key`)({
    component: Task
})

function Task() {
    const { key } = Route.useParams()
    const taskDataStatus = useTasks()
    const housekeepingDataStatus = useHousekeepingOverviewDataStatus()
    const taskData = combineFirestoreFetchStatus([housekeepingDataStatus, taskDataStatus])
    const { status } = taskData
    return match(status)
        .with('loaded', () => <DisplayTask taskKey={key} />)
        .otherwise(() => <UI.Loader />)
}

function DisplayTask({ taskKey }: { taskKey: string }) {
    const task = useTaskData(taskKey)
    if (!task) throw new Error('Task not found')

    return match(task)
        .with({ type: 'issue' }, () => <IssueModal issueKey={task.key} />)
        .with({ type: 'general' }, c => <GeneralTaskModal task={c} />)
        .with({ type: 'cleaning' }, () => task.area?.key && <AreaSummaryModal areaKey={task.area.key} />)
        .otherwise(() => <div>Task type is wrong</div>)
}
