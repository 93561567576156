import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { reportsSelector } from '@shared-snap/snap/components/reports/state/reports-state'
import type { DateFilterValue } from '@shared-snap/snap/components/reports/state/reports-state'
import { DateRangePicker } from 'components/atoms/date-range-picker/date-range-picker'
import DateRangeOptions from 'components/atoms/reports/date-range-options'
import type { FilterType } from 'components/atoms/reports/date-range-options'
import moment from 'moment-timezone'
import type React from 'react'
import { useRecoilState } from 'recoil'
import { dateOptions } from './data'
import { FilterRow, LeftColumn, MiddleColumn, RightColumn } from './style'

export function ReportsFilter({
    dateFilter = ['Today', 'Yesterday', 'This month', 'Last month'],
    defaultDateFilter = 'Today',
    children
}: {
    dateFilter?: DateFilterValue[]
    defaultDateFilter?: DateFilterValue
    children?: React.ReactNode
}) {
    const currentOrganization = useCurrentOrg()
    const [reportParams, setReportParams] = useRecoilState(reportsSelector)

    const dateOptionsForOrg = dateOptions(currentOrganization!.timezone)
    const initialValues: FilterType['filter'] = {
        name: '',
        dateFilter: defaultDateFilter,
        startDate: reportParams.startDate.valueOf(),
        endDate: reportParams.endDate.valueOf(),
        dateFilterType: dateOptionsForOrg.find(option => option.dateFilter === defaultDateFilter)?.dateFilterType ?? 'date'
    }

    // TODO calendar should handle date range instead of just one day.
    const onChange = (start: string, end: string) => {
        console.log('Changing superset filter via date picker:', start, 'to', end)
        const startDate = moment.tz(start, currentOrganization.timezone).startOf('day')
        const endDate = moment.tz(end, currentOrganization.timezone).add(1, 'day').startOf('day')
        setReportParams({ ...reportParams, startDate, endDate })
    }

    const initialDate = moment.tz(initialValues.startDate, currentOrganization.timezone).format('YYYY-MM-DD')
    const filteredDateOptions = dateOptionsForOrg.filter(option => dateFilter.includes(option.dateFilter))

    return (
        <div>
            <FilterRow className="row">
                <LeftColumn className="col-lg-4">{children}</LeftColumn>
                <MiddleColumn className="col-lg-4">
                    <div className="flex justify-center items-center h-100">
                        <DateRangePicker value={initialDate} onChange={onChange} />
                    </div>
                </MiddleColumn>
                <RightColumn className="col-lg-4">
                    <DateRangeOptions values={filteredDateOptions} />
                </RightColumn>
            </FilterRow>
        </div>
    )
}
