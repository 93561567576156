import { UI } from '@shared-snap/snap/registry/ui-elements-registry'

interface Props {
    label: string
    horizontal?: boolean
    required?: boolean
    children?: React.ReactNode
    className?: string
    fieldName?: string
}

export function FieldLabel({ label, children, required = false, horizontal = false, className, fieldName }: Props) {
    return (
        <div className={`flex w-full justify-between gap-2 ${horizontal ? 'items-center' : 'flex-col'} ${className}`}>
            <label className=" text-sm text-snap-dark-gray cursor-pointer" htmlFor={fieldName}>
                {label}
                {required && <span className="text-snap-red text-sm ml-1">*</span>}
            </label>
            {children}
        </div>
    )
}
