import { userHasAccessToAreaGroup } from '@shared/area-groups-helpers'
import type { AreaSummaryProjectionBooking, TaskStruct } from '@shared/firestore-structs'
import {
    getGeneralActiveTasksQueryWithPMSIntegration,
    getGeneralCompletedTasksQueryWithPMSIntegration,
    getHousekeepingTasksQueryWithPMSIntegration,
    getIssueTasksQueryWithPMSIntegration,
    getTaskQueryByKey
} from '@shared/task-data'
import { type ApaleoQueryParams, TaskboardContext } from '@shared/traces-types'
import { type UserOption, constructUsersOptions } from '@shared/user-data'
import moment from 'moment'
import { clone } from 'ramda'
import { useEffect, useMemo } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { match } from 'ts-pattern'
import { useFetchStatus, useSplitFirestoreQuery } from '../../../infra/firestore-atom'
import { constructOptions } from '../../../utils'
import { sortTasksByUrgency } from '../../../utils/housekeeping-utils'
import { useCurrentOrg, useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useUsers } from '../../auth/hooks/use-users'
import { areasSelector } from '../../auth/state/login'
import { useCreateBookingDisplay } from '../../housekeeping/area-summary/hooks/use-area-summary'
import type { DisplayedBooking } from '../../housekeeping/area-summary/state/area-summary-state'
import { housekeepingOverview, housekeepingOverviewDate } from '../../housekeeping/housekeeping-overview/state/housekeeping-overview-state'
import { integrationParamsAtom } from '../../traces/state/atoms'
import {
    allTasksBySectionSelector,
    allTasksSelector,
    assignedLateTasksSelector,
    assignedTasksSelector,
    currentTaskAtom,
    currentTasksTypesAtom,
    doneTasksSelector,
    dueLateTasksSelector,
    dueTasksSelector,
    generalActiveTasksAtom,
    generalCompletedTasksAtom,
    housekeepingTasksAtom,
    issueTasksAtom,
    myTasksSelector,
    priorityFilterAtom,
    searchInputValueAtom,
    startDateAtom,
    taskSelector,
    taskboardContextAtom
} from '../state/my-tasks'

export function useTasks() {
    const currentUser = useCurrentUser()
    const startDate = useRecoilValue(startDateAtom)
    const currentTasksTypes = useRecoilValue(currentTasksTypesAtom)
    const context = useRecoilValue(taskboardContextAtom)
    const integrationParams = useRecoilValue(integrationParamsAtom)

    if (!currentUser) throw new Error('User not logged in')

    const queries = {
        housekeepingTasksWithPMSIntegration: {
            query: getHousekeepingTasksQueryWithPMSIntegration,
            atom: useSetRecoilState(housekeepingTasksAtom)
        },
        issueTasksWithPMSIntegration: {
            query: getIssueTasksQueryWithPMSIntegration,
            atom: useSetRecoilState(issueTasksAtom)
        },
        generalActiveTasksWithPMSIntegration: {
            query: getGeneralActiveTasksQueryWithPMSIntegration,
            atom: useSetRecoilState(generalActiveTasksAtom)
        },
        generalCompletedTasksWithPMSIntegration: {
            query: getGeneralCompletedTasksQueryWithPMSIntegration,
            atom: useSetRecoilState(generalCompletedTasksAtom)
        }
    } as const

    return useFetchStatus(
        ...Object.entries(queries).map(([name, { query, atom }]) =>
            useSplitFirestoreQuery<TaskStruct>(
                tasks => {
                    const filteredTasks = tasks.filter(
                        task => userHasAccessToAreaGroup(currentUser.areaGroups, task.area?.group) || !task.areaKey
                    )
                    atom(filteredTasks)
                },
                () => null,
                db =>
                    query(db, {
                        organizationKey: currentUser.organizationKey,
                        date: startDate,
                        integrationParams,
                        context,
                        currentTasksTypes
                    }),
                name
            )
        )
    )
}
export function useDueTasks() {
    const areas = useRecoilValue(housekeepingOverview)
    const dueTasks = useRecoilValue(dueTasksSelector)
    const dueLateTasks = useRecoilValue(dueLateTasksSelector)
    const dueTaskCount = dueTasks.length + dueLateTasks.length
    return {
        dueTasks: sortTasksByUrgency(clone(dueTasks), areas),
        dueLateTasks: sortTasksByUrgency(clone(dueLateTasks), areas),
        dueTaskCount
    }
}

export function useAssignedTasks() {
    const areas = useRecoilValue(housekeepingOverview)
    const assignedTasks = useRecoilValue(assignedTasksSelector)
    const assignedLateTasks = useRecoilValue(assignedLateTasksSelector)
    const assignedTaskCount = assignedTasks.length + assignedLateTasks.length
    return {
        assignedTasks: sortTasksByUrgency(clone(assignedTasks), areas),
        assignedLateTasks: sortTasksByUrgency(clone(assignedLateTasks), areas),
        assignedTaskCount
    }
}

export function useDoneTasks() {
    const areas = useRecoilValue(housekeepingOverview)
    const doneTasks = useRecoilValue(doneTasksSelector)
    return sortTasksByUrgency(clone(doneTasks), areas)
}

export function useMyTasks() {
    const areas = useRecoilValue(housekeepingOverview)
    const result = useRecoilValue(myTasksSelector)
    return {
        ...result,
        due: sortTasksByUrgency(clone(result.due), areas),
        overdue: sortTasksByUrgency(clone(result.overdue), areas),
        createdByMe: sortTasksByUrgency(clone(result.createdByMe), areas)
    }
}
export function useAllTasksBySections() {
    const result = useRecoilValue(allTasksBySectionSelector)
    const areas = useRecoilValue(housekeepingOverview)
    return {
        ...result,
        due: sortTasksByUrgency(clone(result.due), areas),
        overdue: sortTasksByUrgency(clone(result.overdue), areas),
        createdByMe: sortTasksByUrgency(clone(result.createdByMe), areas)
    }
}

export function useCurrentTasksTypes() {
    return useRecoilState(currentTasksTypesAtom)
}

export function usePriorityFilter() {
    return useRecoilState(priorityFilterAtom)
}

export function useTasksDate() {
    const currentOrg = useCurrentOrg()
    const [startDate, setStartDate] = useRecoilState(housekeepingOverviewDate)

    function setDate(date: string) {
        setStartDate(moment.tz(date, currentOrg.timezone))
    }

    return [startDate, setDate] as const
}

export function useTasksSearch() {
    return useRecoilState(searchInputValueAtom)
}

export function useAreasOptions() {
    const items = useRecoilValue(areasSelector)
    const integrationParams = useRecoilValue(integrationParamsAtom)
    const listOfAreas = constructOptions(items)

    if (integrationParams?.propKey) {
        return listOfAreas.filter(l => l.propKey === integrationParams?.propKey)
    } else {
        return listOfAreas
    }
}

export function useUsersOptions(): UserOption[] {
    return constructUsersOptions(useUsers())
}

export function useTaskUsersOptions(key: string): readonly [string[], UserOption[]] {
    const task = useTaskData(key)
    const options = useUsersOptions()
    return useMemo(() => [task?.assignedTo?.map(u => u.key) ?? [], options], [task?.assignedTo, options])
}

export function useTask(taskKey: string) {
    const tasks = useRecoilValue(allTasksSelector)
    const status = useFetchTask(taskKey)
    const setCurrentTask = useSetRecoilState(currentTaskAtom)

    useEffect(() => {
        return () => {
            setCurrentTask(null)
        }
    }, [])

    return tasks.length === 0 ? status : { status: 'loaded' }
}

export function useTaskData(taskKey: string) {
    return useRecoilValue(taskSelector(taskKey))
}

export function useTaskBooking(taskKey: string): DisplayedBooking | null {
    const task = useTaskData(taskKey)
    const createBookingDisplay = useCreateBookingDisplay()
    const overview = useRecoilValue(housekeepingOverview)
    const [date] = useTasksDate()
    const currentOrg = useCurrentOrg()

    if (task.area?.key) {
        const booking = overview[task.area.key]?.leadBooking

        if (!booking) return null

        const { guestName, nrOfGuests, checkinDate, checkoutDate, bedSetup, notes } = booking

        return createBookingDisplay(
            { nrOfGuests, checkinDate, checkoutDate, guestName, bedSetup, notes } as AreaSummaryProjectionBooking,
            moment.tz(date, currentOrg.timezone)
        )
    }
    return null
}

function useFetchTask(key: string) {
    const user = useCurrentUser()
    const setCurrentTask = useSetRecoilState(currentTaskAtom)

    return useFetchStatus(
        useSplitFirestoreQuery<TaskStruct>(
            tasks => setCurrentTask(tasks[0]),
            () => setCurrentTask(null),
            db => getTaskQueryByKey(db, user.organizationKey, key)
        )
    )
}

export function useAllTasksCount() {
    const { assignedTaskCount } = useAssignedTasks()
    const { dueTaskCount } = useDueTasks()
    const doneTasks = useDoneTasks()
    return assignedTaskCount + dueTaskCount + doneTasks.length
}

export function useSetTaskboardContext() {
    const setTaskboardContext = useSetRecoilState(taskboardContextAtom)
    return (pathname: string, locationSearch: ApaleoQueryParams) => {
        console.debug(`Updating taskboard context with Pathname: ${pathname}, locationSearch: ${locationSearch}`)
        const context = match(pathname)
            .with('/snap/integrations/apaleo/taskboard', () => {
                const reservationId = locationSearch.reservationId
                const propertyId = locationSearch.propertyId

                const context = reservationId
                    ? TaskboardContext.RESERVATIONS
                    : propertyId
                      ? TaskboardContext.PROPERTY
                      : TaskboardContext.ACCOUNT

                console.debug(`Setting taskboard context to ${context}`)
                return context
            })
            .otherwise(() => {
                return TaskboardContext.EMBEDDED
            })
        console.debug(`Setting taskboard context to ${context}`)
        setTaskboardContext(context)
        return context
    }
}

export function useTaskboardContext() {
    const taskboardContext = useRecoilValue(taskboardContextAtom)

    return { taskboardContext }
}
