import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useUnitsGroups } from '@shared-snap/snap/components/settings/hooks/use-units'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { ZIndex } from '@shared/css-constants'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'

type SelectGroupModalProps = {
    isOpen: boolean
    onClose: () => void
    onSelect: (group: { value: string; label: string }) => void
    onCreate: (group: { value: string; label: string }) => void
}
export const SelectGroupModal = ({ isOpen, onClose, onSelect, onCreate }: SelectGroupModalProps) => {
    const {
        LL: { settingsWeb, settings }
    } = useI18nContext()
    const groups = useUnitsGroups()
    const [innerGroups, setInnerGroups] = useState(groups.map(group => ({ value: group, label: group })))

    useEffect(() => {
        setInnerGroups(groups.map(group => ({ value: group, label: group })))
    }, [groups])

    return (
        <Modal className="bg-white min-w-[400px] rounded-lg shadow-lg -mt-[10%]" isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose} className="pb-2 rounded-lg">
                <UI.Text size="md" color="snap-black">
                    {settings.units.groupPlaceholder()}
                </UI.Text>
            </ModalHeader>
            <CreatableSelect
                className="min-w-[200px] m-4"
                autoFocus
                openMenuOnFocus
                closeMenuOnSelect
                name="group"
                menuPosition="fixed"
                menuPlacement="auto"
                options={innerGroups}
                isClearable
                isSearchable
                placeholder={settingsWeb.units.groupPlaceholder()}
                isMulti={false}
                styles={{
                    container: provided => ({
                        ...provided,
                        zIndex: ZIndex.tooltip
                    }),
                    menu: provided => ({
                        ...provided,
                        zIndex: ZIndex.tooltip
                    }),
                    menuList: provided => ({
                        ...provided,
                        zIndex: ZIndex.tooltip
                    })
                }}
                onChange={selectedOption => {
                    if (selectedOption) {
                        onSelect(selectedOption)
                    }
                    onClose()
                }}
                onCreateOption={(inputValue: string) => {
                    if (!inputValue.trim()) {
                        return
                    }
                    const newOption = { value: inputValue, label: inputValue }

                    onCreate(newOption)
                    setInnerGroups(prev => [...prev, newOption])

                    onClose()
                }}
            />
        </Modal>
    )
}
