import { userHasAccessToAreaGroup } from '@shared/area-groups-helpers'
import type { ConfirmationResult } from '@shared/firebase'
import type { OrgStruct, RuleStruct, UserStruct } from '@shared/firestore-structs'
import type { AreasProjectedWithLocalState_v2 } from '@shared/projections-v2'
import { remapToNumber } from '@shared/projections/projection-ticks'
import type { Subscription } from '@shared/subscriptions/subscription'
import { atom, selector } from 'recoil'
import { overrideableStateNoTime } from '../../../infra/overrideable-state-no-time'

export const confirmationRef = { current: null as ConfirmationResult | null }

export const isAuthenticatedAtom = atom<boolean>({
    key: 'login-isAuthenticated',
    default: false
})
export const loggedInUser = atom<UserStruct | null>({ key: 'login-loggedInUser', default: null })
export const currentUser = selector<UserStruct>({
    key: 'login-currentUser',
    get: ({ get }) => {
        const user = get(loggedInUser)
        if (!user) {
            throw new Error('User not logged in')
        }
        return user
    }
})
export const loggedInLoading = atom<boolean>({ key: 'login-loggedInLoading', default: true })

export const stopAuthListenerAtom = atom<boolean>({ key: 'login-authListenerIsActive', default: false })
export const currentOrg = atom<OrgStruct | null>({
    key: 'currentOrg',
    default: null
})
export const currentOrgSelector = selector<OrgStruct>({
    key: 'currentOrgSelector',
    get: ({ get }) => {
        const org = get(currentOrg)
        if (!org) {
            throw new Error('Organization not loaded')
        }
        return org
    },
    set: ({ set }, newValue) => {
        set(currentOrg, newValue)
    }
})

const usersAtom = atom<UserStruct[]>({
    key: 'login-users',
    default: []
})

export const usersSelector = selector<UserStruct[]>({
    key: 'login-usersSelector',
    get: ({ get }) => {
        const users = get(usersAtom)
        //TODO: add the logic to filter users by access to the area
        return users
    },
    set: ({ set }, newValue) => {
        set(usersAtom, newValue)
    }
})

const { frontendSetter, backendSetter, resolverValue } = overrideableStateNoTime<AreasProjectedWithLocalState_v2>({
    id: 'login-areasAtom',
    overrideDiscarder: (backend, override, id) => {
        const result = remapToNumber(backend.ticks) >= override.ticks ? 'discard' : 'keep'
        console.log(
            `<areas ${backend.name}(key:${backend.key})>Comparing backend ticks ${backend.ticks} with override ticks ${override.ticks}, result: ${result}`
        )
        return result
    }
})
export const areasBackendSetter = backendSetter
export const areasFrontendSetter = frontendSetter
export const areasSelector = selector<AreasProjectedWithLocalState_v2[]>({
    key: 'login-areasSelector',
    get: ({ get }) => {
        const user = get(currentUser)
        return Object.values(get(resolverValue)).filter(area => userHasAccessToAreaGroup(user.areaGroups, area.group))
    }
})
export const areasHousekeepingSelectorWithAccess = selector<{ [key: string]: AreasProjectedWithLocalState_v2 }>({
    key: 'login-areasHousekeepingSelectorWithAccess',
    get: ({ get }) => {
        const areas = get(resolverValue)
        console.debug(`Getting areas count: ${Object.keys(areas).length}`)
        return areas
    }
})

export const rulesAtom = atom<RuleStruct[]>({
    key: 'login-rulesAtom',
    default: []
})

export const subscriptionAtom = atom<Subscription | null>({
    key: 'login-subscriptionAtom',
    default: null
})

export const sideBarOpenAtom = atom<boolean>({
    key: 'login-sideBarOpen',
    default: true
})
