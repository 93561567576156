import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { reportsSelector } from '@shared-snap/snap/components/reports/state/reports-state'
import type { DateFilterType, DateFilterValue } from '@shared-snap/snap/components/reports/state/reports-state'

import { red_sweeply, yellow_sweeply } from '@shared/colors'
import moment from 'moment-timezone'
import type React from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

export type FilterType = {
    filter: {
        name: string
        startDate: number
        endDate: number
        dateFilter: DateFilterValue
        dateFilterType: DateFilterType
    }
    sortOrder: 'asc' | 'desc'
    sortField: 'name'
}

export type DateOptionType = {
    dateFilter: DateFilterValue
    startDate: number
    endDate: number
    dateFilterType: DateFilterType
}

export type NavLinkProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    active: boolean
}

// TODO remove styled-components in favor of whatever it was replaced with
export const NavLink = styled.button<NavLinkProps>`
    font-weight: bold;
    color: ${props => (props.active ? `${red_sweeply} !important` : 'inherit')};
    border-bottom: ${props => (props.active ? `2px solid ${yellow_sweeply} !important` : 'inherit')};
    background: none;

    &:hover {
        color: ${red_sweeply} !important;
        border-bottom: 2px solid ${yellow_sweeply} !important;
    }
`

function DateRangeOptions({
    values,
    disabled = false
}: {
    values: DateOptionType[]
    disabled?: boolean
}) {
    const [reportParams, setReportParams] = useRecoilState(reportsSelector)
    const currentOrg = useCurrentOrg()

    const listItems = values.map(value => {
        const onClick = (_: React.MouseEvent) => {
            const startDate = moment.tz(value.startDate, currentOrg.timezone).startOf('day')
            const endDate = moment.tz(value.endDate, currentOrg.timezone).add(1, 'day').startOf('day')
            const selectedFilter = value.dateFilter
            setReportParams({ startDate, endDate, selectedFilter })
        }

        return (
            <li key={value.dateFilter} className="nav-item" onClick={onClick}>
                <NavLink
                    disabled={disabled}
                    active={reportParams.selectedFilter === value.dateFilter}
                    className={`nav-link ${reportParams.selectedFilter === value.dateFilter ? 'active' : ''}`}>
                    {value.dateFilter}
                </NavLink>
            </li>
        )
    })

    return <ul className="flex nav nav-tabs nav-tabs-line d-flex">{listItems}</ul>
}

export default DateRangeOptions
