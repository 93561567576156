import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Colors } from '@shared-snap/snap/types/visuals.types'
import moment from 'moment'
import { useState } from 'react'
import { useCallback } from 'react'
import ReactDatePicker from 'react-datepicker'
import { IconButton } from '../icon-button'
import Tooltip from '../tooltip'
import 'react-datepicker/dist/react-datepicker.css'
import './custom-datepicker.css'

type DateRangePickerProps = {
    value: string
    onChange: (start: string, end: string) => void
    dateFormatOverride?: string | string[]
    disabled?: boolean
}

export function DateRangePicker(props: DateRangePickerProps) {
    const { locale } = useI18nContext()
    const { value, onChange, disabled = false, dateFormatOverride } = props
    const [startDate, setStartDate] = useState<Date | null>(new Date())
    const [endDate, setEndDate] = useState<Date | null>(null)

    const handleValueChange = useCallback(
        (newStart: Date | null, newEnd: Date | null) => {
            if (!newStart && !newEnd) return
            setStartDate(newStart)
            setEndDate(newEnd)
            const start = moment(newStart).format('YYYY-MM-DD')
            const end = moment(newEnd).format('YYYY-MM-DD')
            onChange(start, end)
        },
        [onChange]
    )

    const inputClassName = `bg-snap-light-yellow border border-snap-yellow rounded-[10px] text-[16px] text-snap-black font-bold text-center`

    return (
        <ReactDatePicker
            disabled={disabled}
            selected={startDate}
            className={`${inputClassName} w-[130px]`}
            onChange={([start, end]) => handleValueChange(start, end)}
            startDate={startDate}
            endDate={endDate}
            popperProps={{
                strategy: 'fixed'
            }}
            locale={locale}
            dateFormat={dateFormatOverride ?? 'MMM do, yyyy'}
            selectsRange
        />
    )
}
