import { userHasAccessToAreaGroup } from '@shared/area-groups-helpers'
import type {
    AreaCleaningStatus,
    AreaStruct,
    AreaSummaryProjectionBooking,
    BookingStruct,
    Occupancy,
    RuleStruct,
    TaskStruct,
    UserStruct
} from '@shared/firestore-structs'
import type { AreaBoxSummary_v2, AreasProjectedWithLocalState_v2, AreasProjected_v2 } from '@shared/projections-v2'
import * as c from '@shared/txt-constants'
import type { Optional } from '@shared/type-utils'
import moment from 'moment'
import { P, match } from 'ts-pattern'
import type { Translation } from '../../i18n/i18n-types'
import type { ScheduleDayInterface } from '../components/housekeeping/area-summary/state/area-summary-state'
import type { AreaBoxGroupFormatted, WorkloadCounts } from '../types/housekeeping.types'
import type { CleaningStatusColor, Colors, Icon } from '../types/visuals.types'
import type { HousekeepingOverviewCombined } from '../user-actions/housekeeping-overview-actions'

const priorityOccupancy = {
    checkin: 10,
    turnover: 8,
    'stayover-80': 5,
    stayover: 3,
    checkout: 2,
    vacant: 1
} as const satisfies { [name in Occupancy]: number }

const priorityCleaningStatus = {
    dirty: 10,
    'waiting-for-checkout': 5,
    'do-not-disturb': 4,
    'no-service': 3,
    'no-cleaning-service': 2,
    clean: 1,
    'preparing-pause': 1,
    preparing: 1,
    inspection: 1,
    'out-of-service': 1,
    other: 1,
    all: 1
} as const satisfies { [name in AreaCleaningStatus]: number }

function taskScore(task: TaskStruct, areas: { [p: string]: HousekeepingOverviewCombined[0] }) {
    const defaultCleaningStatus = 'clean'
    const defaultOccupancyStatus = 'vacant'
    const taskArea = task.areaKey ? areas[task.areaKey] : undefined
    if (!taskArea) console.warn(`Could not find area for task ${task.key} with areaKey ${task.areaKey}. Will use some dubious defaults`)

    const areaGroupWeight = taskArea?.groupIdx ?? 0
    const taskPriorityWeight = task.priority ? 0 : 1
    const areaNameWeight = taskArea?.nameIdx ?? 0
    const areaCleaningStatusWeight = priorityCleaningStatus[taskArea?.cleaningStatus ?? defaultCleaningStatus]
    const areaOccupancyWeight = priorityOccupancy[taskArea?.occupancy ?? defaultOccupancyStatus]

    return (
        areaGroupWeight * 10_000_000_000 + // group weight expected range is less than 1000
        taskPriorityWeight * 1_000_000 +
        areaOccupancyWeight * 100_000 +
        areaNameWeight * 100 + // area name weight expected range is less than 1000
        areaCleaningStatusWeight
    )
}

export function sortTasksByUrgency(
    tasks: TaskStruct[],
    areas: {
        [p: string]: HousekeepingOverviewCombined[0]
    }
) {
    tasks.sort((a, b) => taskScore(a, areas) - taskScore(b, areas))

    return tasks
}

export function groupSorting(a: { groupIdx: number }, b: { groupIdx: number }) {
    return a.groupIdx - b.groupIdx
}
export function nameSorting(a: { nameIdx: number }, b: { nameIdx: number }) {
    return a.nameIdx - b.nameIdx
}

const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })

export function lowercaseStringSorting(groupNameA: string, groupNameB: string) {
    return collator.compare(groupNameA.toLowerCase(), groupNameB.toLowerCase())
}

export function generalStringSorting(a: Pick<AreaStruct, 'name'>, b: Pick<AreaStruct, 'name'>) {
    return collator.compare(a.name, b.name)
}

export function formatHousekeepingOverview(
    overviews: HousekeepingOverviewCombined,
    areas: { [k: string]: Pick<AreasProjectedWithLocalState_v2, 'key' | 'group' | 'groupIdx' | 'nameIdx'> },
    currentUser: UserStruct
): AreaBoxGroupFormatted[] {
    if (!overviews) return []

    const groupsWithAreaKeys = Object.values(areas).reduce(
        (acc, { key, group }) => {
            const groupName = group || 'Uncategorized'
            const hasAccess = userHasAccessToAreaGroup(currentUser.areaGroups, groupName)
            if (!hasAccess) return acc
            acc[groupName] ||= []
            acc[groupName].push(key)
            return acc
        },
        {} as { [groupName: string]: string[] }
    )

    return Object.keys(groupsWithAreaKeys)
        .sort((a, b) => {
            return groupSorting(areas[groupsWithAreaKeys[a][0]], areas[groupsWithAreaKeys[b][0]])
        })
        .map(group => ({
            group,
            areaBoxSummaries: groupsWithAreaKeys[group]
                .map(key => ({
                    ...overviews[key],
                    areaKey: key
                }))
                .sort((a, b) => nameSorting(areas[a.areaKey], areas[b.areaKey]))
        }))
}

function shouldApplyStayoverRule(occupancy: 'stayover' | 'stayover-80', rules: RuleStruct[], cleaningStatus: AreaCleaningStatus): boolean {
    if (cleaningStatus === 'no-cleaning-service') return false

    const relevantRule = rules.find(
        x =>
            (x.repeatType === 'custom' || x.repeatType === 'optin') &&
            x.trigger === 'booking' &&
            (occupancy === 'stayover' ? x.repeatInterval === 1 : x.repeatInterval !== 1)
    )

    return !!relevantRule
}

export function pickAreaIcons({
    occupancy,
    cleaningStatus,
    guestCheckedIn,
    note,
    dailyComments,
    rules
}: Optional<
    Pick<HousekeepingOverviewCombined[0], 'occupancy' | 'cleaningStatus' | 'guestCheckedIn' | 'note' | 'dailyComments'> & {
        rules: RuleStruct[]
    },
    'note'
>): Icon[] {
    const conditions: { check: () => boolean; icon: Icon }[] = [
        { check: () => ['checkin', 'turnover'].includes(occupancy), icon: 'specta-check-in' },
        { check: () => ['inspection'].includes(cleaningStatus), icon: 'specta-issue-inspection' },
        { check: () => ['preparing-pause'].includes(cleaningStatus), icon: 'specta-pause' },
        { check: () => guestCheckedIn || cleaningStatus === 'waiting-for-checkout', icon: 'specta-people' },
        { check: () => !!note, icon: 'specta-note' },
        {
            check: () => dailyComments && dailyComments.length > 0 && dailyComments[0].comment.trim().length > 0,
            icon: 'specta-daily-comment'
        },
        {
            check: () => occupancy === 'stayover' && shouldApplyStayoverRule(occupancy, rules, cleaningStatus),
            icon: 'specta-broom'
        },
        {
            check: () => occupancy === 'stayover-80' && shouldApplyStayoverRule(occupancy, rules, cleaningStatus),
            icon: 'specta-bed'
        }
    ]

    const baseIcons = match<[Occupancy, AreaCleaningStatus], Icon[]>([occupancy, cleaningStatus])
        .with([P._, 'no-cleaning-service'], () => ['specta-leaf'])
        .with([P._, 'do-not-disturb'], () => ['specta-moon'])
        .with(['stayover', 'clean'], () => ['specta-people'])
        .with(['checkout', P._], () => ['specta-suitcase'])
        .with([P._, 'out-of-service'], () => ['specta-stop-sign'])
        .with([P._, 'no-service'], () => ['specta-stop-sign'])
        .with(['vacant', P._], () => [])
        .with(['turnover', P._], () => ['specta-suitcase'])
        .otherwise(() => [])

    const relevantIcons = conditions.reduce((icons, condition) => {
        if (condition.check()) {
            icons.push(condition.icon)
        }
        return icons
    }, baseIcons)
    return [...new Set(relevantIcons)]
}

export function pickCleaningStatusColor({
    cleaningStatus,
    occupancy
}: Pick<HousekeepingOverviewCombined[0], 'cleaningStatus' | 'occupancy'>): CleaningStatusColor {
    return match([cleaningStatus, occupancy])
        .with(['dirty', 'stayover'], () => 'sweeply-rose')
        .with(['dirty', 'stayover-80'], () => 'sweeply-rose')
        .with(['dirty', P._], () => 'sweeply-red')
        .with(['preparing', P._], () => 'sweeply-yellow')
        .with(['preparing-pause', P._], () => 'sweeply-yellow')
        .with(['inspection', P._], () => 'sweeply-curry-yellow')
        .with(['clean', P._], () => 'sweeply-green')
        .with(['do-not-disturb', P._], () => 'sweeply-blue')
        .with(['no-service', P._], () => 'sweeply-mid-gray')
        .with(['no-cleaning-service', P._], () => 'sweeply-teal')
        .with(['waiting-for-checkout', P._], () => 'sweeply-purple')
        .with(['other', P._], () => 'sweeply-black')
        .otherwise(() => 'sweeply-black') as CleaningStatusColor
}

export const occupancyList: Occupancy[] = ['vacant', 'turnover', 'stayover', 'checkin', 'checkout', 'stayover-80']

export const occupancyOptions = occupancyList.map(value => ({
    value,
    label: occupancyToHuman(value)
}))

export const cleaningStatuses: AreaCleaningStatus[] = [
    'clean',
    'waiting-for-checkout',
    'dirty',
    'no-service',
    'preparing',
    'do-not-disturb',
    'inspection',
    'no-cleaning-service',
    'preparing-pause',
    'other'
]
export const cleaningStatusOptions = cleaningStatuses.map(value => ({ value, label: cleaningStatusToHuman(value, null) }))
export function countWorkload(
    areas: Pick<AreaStruct, 'occupancy' | 'cleaningStatus' | 'cleaningPriority' | 'guestCheckedIn'>[],
    rules: RuleStruct[]
): WorkloadCounts {
    return areas.reduce(
        (acc, area) => {
            match([area.occupancy, area.cleaningStatus])
                .with([P._, 'no-cleaning-service'], () => acc.noServiceCount.count++)
                .with(['checkout', P._], () => acc.unclean100Count.count++)
                .with(['stayover', P._], () => {
                    if (shouldApplyStayoverRule('stayover', rules, area.cleaningStatus)) {
                        acc.unclean50Count.count++
                    }
                })
                .with(['stayover-80', P._], () => {
                    if (shouldApplyStayoverRule('stayover-80', rules, area.cleaningStatus)) {
                        acc.unclean80Count.count++
                    }
                })

                .with(['checkin', P._], () => {
                    acc.checkInCount.count++
                    acc.otherWorkloadCount.count++
                })
                .with(['turnover', P._], () => {
                    acc.checkInCount.count++
                    acc.unclean100Count.count++
                })
                .otherwise(() => acc.otherWorkloadCount.count++)

            return acc
        },
        {
            unclean50Count: { count: 0, icon: 'specta-broom' },
            unclean80Count: { count: 0, icon: 'specta-bed' },
            unclean100Count: { count: 0, icon: 'specta-suitcase' },
            noServiceCount: { count: 0, icon: 'specta-leaf' },
            checkInCount: { count: 0, icon: 'specta-check-in' },
            otherWorkloadCount: { count: 0, icon: 'specta-workload' }
        }
    )
}

export function formatTime(time: number) {
    const duration = moment.duration(time, 'seconds')
    const sections =
        duration.hours() > 0 ? [duration.hours(), duration.minutes(), duration.seconds()] : [duration.minutes(), duration.seconds()]
    return sections.map(s => s.toString().padStart(2, '0')).join(':')
}

export function getCleaningStatusesWithColor() {
    return cleaningStatuses.map(status => ({
        status,
        color: pickCleaningStatusColor({ cleaningStatus: status, occupancy: 'vacant' })
    }))
}

export function pickOccupancyIcons(occupancy: Occupancy | 'all'): Icon {
    return match(occupancy)
        .with('stayover', () => 'specta-broom' as const)
        .with('stayover-80', () => 'specta-bed' as const)
        .with('turnover', () => 'specta-turnover' as const)
        .with('checkin', () => 'specta-check-in' as const)
        .with('checkout', () => 'specta-suitcase' as const)
        .with('vacant', () => 'specta-vacant' as const)
        .with('all', () => 'specta-all' as const)
        .otherwise(() => 'specta-all' as const)
}

export function getOccupancyListWithIcons() {
    return occupancyList.map(occupancy => ({
        occupancy,
        icon: pickOccupancyIcons(occupancy)
    }))
}

export function iconsPositionResolver(icons: Icon[]) {
    const top: Icon[] = [
        'specta-suitcase',
        'specta-leaf',
        'specta-bed',
        'specta-broom',
        'specta-daily-comment',
        'specta-stop-sign',
        'specta-pause'
    ]
    return icons.reduce(
        (acc, icon) => {
            if (top.includes(icon)) {
                acc.top.push(icon)
            } else {
                acc.bottom.push(icon)
            }
            return acc
        },
        { top: [], bottom: [] } as { top: Icon[]; bottom: Icon[] }
    )
}

export const statusesAndIcons: { icon: Icon; label: keyof Translation['quickGuide']['statusesAndIcons'] }[] = [
    { icon: 'specta-priority-low', label: 'priority' },
    { icon: 'specta-people', label: 'guestsInRoom' },
    { icon: 'specta-broom', label: '50unclean' },
    { icon: 'specta-check-in', label: 'checkin' },
    { icon: 'specta-bed', label: '80unclean' },
    { icon: 'specta-suitcase', label: '100unclean' },
    { icon: 'specta-moon', label: 'dontDisturb' },
    { icon: 'specta-issue-inspection', label: 'inspection' },
    { icon: 'specta-stop-sign', label: 'outOfService' },
    { icon: 'specta-leaf', label: 'noService' },
    { icon: 'specta-daily-comment', label: 'dailyComments' },
    { icon: 'specta-note', label: 'unitNotes' }
]

export const cleaningStatusesAndColors: { color: Colors; text: keyof Translation['quickGuide']['statusesAndIcons'] }[] = [
    { color: 'sweeply-purple', text: 'checkout' },
    { color: 'sweeply-rose', text: '50or80unclean' },
    { color: 'sweeply-curry-yellow', text: 'inspection' },
    { color: 'sweeply-blue', text: 'dontDisturb' },
    { color: 'sweeply-yellow', text: 'preparing' },
    { color: 'sweeply-red', text: '100unclean' },
    { color: 'sweeply-green', text: 'clean' },
    { color: 'sweeply-mid-gray', text: 'outOfService' },
    { color: 'sweeply-teal', text: 'noService' }
]

export function transformToFlatArray(
    areaBoxes: AreaBoxGroupFormatted[]
): (string | (HousekeepingOverviewCombined[0] & { areaKey: string }))[] {
    const grouped = areaBoxes.reduce(
        (acc, curr) => {
            if (!acc[curr.group]) {
                acc[curr.group] = []
            }
            acc[curr.group].push(...curr.areaBoxSummaries)
            return acc
        },
        {} as Record<string, (HousekeepingOverviewCombined[0] & { areaKey: string })[]>
    )

    return Object.entries(grouped).flatMap(([key, values]) => [key, ...values])
}

export function getObjectsAfterString(
    groupsData: (string | (AreaBoxSummary_v2 & { areaKey: string }))[],
    targetString: string
): (AreaBoxSummary_v2 & { areaKey: string })[] {
    const result: (AreaBoxSummary_v2 & { areaKey: string })[] = []
    let foundTargetString = false

    for (const item of groupsData) {
        if (typeof item === 'string') {
            if (item === targetString) {
                foundTargetString = true
            } else if (foundTargetString) {
                break
            }
        } else if (foundTargetString) {
            result.push(item)
        }
    }

    return result
}

export function mapBookingToProjection_v2(b: BookingStruct): AreaSummaryProjectionBooking {
    return {
        nrOfGuests: b.adults + b.children,
        adults: b.adults,
        children: b.children,
        checkinDate: b.checkinDate,
        checkoutDate: b.checkoutDate,
        status: b.status,
        guestName: b.guestName,
        notes: b.notes,
        bedSetup: b.bedSetup,
        key: b.key,
        optInDates: b.optInDates,
        optOutDates: b.optOutDates
    }
}

export function groupDaysByWeeks(days: ScheduleDayInterface[]): ScheduleDayInterface[][] {
    const weeks = []
    let currentWeek = Array(7).fill(null)

    days.forEach(day => {
        const dayOfWeek = (moment(day.date).day() + 6) % 7
        currentWeek[dayOfWeek] = day

        if (dayOfWeek === 6) {
            weeks.push(currentWeek)
            currentWeek = Array(7).fill(null)
        }
    })

    if (currentWeek.some(day => day !== null)) {
        weeks.push(currentWeek)
    }

    return weeks
}

export function getFontSize(text: string, modifier = 1): number {
    const maxFontSize = 22 * modifier
    const minFontSize = 12 * modifier
    const lengthThreshold = 24
    const textLength = (text ?? '').length

    const fontSize = maxFontSize - (textLength / lengthThreshold) * (maxFontSize - minFontSize)

    return Math.round(Math.max(fontSize, minFontSize))
}

export function cleaningStatusToHuman(
    cleaningStatus: AreaCleaningStatus,
    occupancy: Occupancy | null
): keyof Translation['shared']['cleaningStatuses'] {
    const statusKeys: Record<string, keyof Translation['shared']['cleaningStatuses']> = {
        [`${c.CLEANING_STATUS_DIRTY}_${c.OCCUPANCY_CHECKOUT}`]: '100unclean',
        [`${c.CLEANING_STATUS_DIRTY}_${c.OCCUPANCY_TURNOVER}`]: '100unclean',
        [`${c.CLEANING_STATUS_DIRTY}_${c.OCCUPANCY_STAYOVER_80}`]: '80unclean',
        [`${c.CLEANING_STATUS_DIRTY}_${c.OCCUPANCY_STAYOVER}`]: '50unclean',
        [c.CLEANING_STATUS_DIRTY]: 'unclean',
        [c.CLEANING_STATUS_PREPARING]: 'preparing',
        [c.CLEANING_STATUS_PREPARING_PAUSE]: 'preparingPause',
        [c.CLEANING_STATUS_INSPECTION]: 'inspection',
        [c.CLEANING_STATUS_CLEAN]: 'clean',
        [c.CLEANING_STATUS_WAITING_FOR_CHECKOUT]: 'checkout',
        [c.CLEANING_STATUS_DO_NOT_DISTURB]: 'dontDisturb',
        [c.CLEANING_STATUS_OUT_OF_SERVICE]: 'outOfService',
        [c.CLEANING_STATUS_NO_SERVICE]: 'noService',
        [c.CLEANING_STATUS_ALL]: 'all',
        [c.CLEANING_STATUS_OTHER]: 'other'
    }

    const key = occupancy ? `${cleaningStatus}_${occupancy}` : cleaningStatus

    return statusKeys[key] ?? 'unknown'
}

export function occupancyToHuman(occupancy: string): keyof Translation['shared']['occupancy'] {
    const translationKeys: Record<string, keyof Translation['shared']['occupancy']> = {
        [c.OCCUPANCY_CHECKIN]: 'checkin',
        [c.OCCUPANCY_CHECKOUT]: 'checkout',
        [c.OCCUPANCY_STAYOVER]: 'stayover',
        [c.OCCUPANCY_STAYOVER_80]: 'stayover',
        [c.OCCUPANCY_TURNOVER]: 'turnover',
        [c.OCCUPANCY_VACANT]: 'vacant',
        [c.OCCUPANCY_ALL]: 'all'
    }

    return translationKeys[occupancy] ?? 'unknown'
}
