import { type Moment, default as moment } from 'moment-timezone'
import { atom, selector } from 'recoil'

export type DateFilterValue = 'Today' | 'Yesterday' | 'This month' | 'Last month' | 'Next week' | 'This week'
export type DateFilterType = 'date' | 'period'

export type ReportParams = {
    startDate: Moment
    endDate: Moment
    selectedFilter: DateFilterValue
}

const now = moment.tz()

export const startDateAtom = atom<Moment>({
    key: 'reports-startDateAtom',
    default: now
})

export const endDateAtom = atom<Moment>({
    key: 'reports-endDateAtom',
    default: now.clone().add(1, 'day')
})

export const selectedFilterAtom = atom<DateFilterValue>({
    key: 'reports-selectedFilterAtom',
    default: 'Today'
})

export const reportsSelector = selector<ReportParams>({
    key: 'reports-reportsSelector',
    get: ({ get }) => {
        const startDate = get(startDateAtom)
        const endDate = get(endDateAtom)
        const selectedFilter = get(selectedFilterAtom)

        return { startDate, endDate, selectedFilter }
    },
    set: ({ set }, newValue) => {
        if ('startDate' in newValue) {
            set(startDateAtom, newValue.startDate)
        }

        if ('endDate' in newValue) {
            set(endDateAtom, newValue.endDate)
        }

        if ('selectedFilter' in newValue) {
            set(selectedFilterAtom, newValue.selectedFilter)
        }
    }
})
